import React, { useEffect, useState } from "react";

const Form = (props) => {
  const [opciones, setOpciones] = useState([]);

  useEffect(() => {
    switch (props.convenio) {
      case "":
        setOpciones([]);
        break;
      case "AXA Colpatria Medicina Prepagada S.A.":
        setOpciones(["Fesalud Plus", "Original Plus", "Alterno Plus"]);
        break;
      case "AXA Colpatria Seguros de Vida S.A.":
        setOpciones([
          "Flexible Empresarial",
          "Salud Ideal",
          "Salud Vital",
          "Salud Vital Plus",
          "Plan Alterno Amparado",
          "H&C Elite",
          "Plan Ambulatorio",
          "Riesgos Laborales",
        ]);
        break;
      case "Sanitas":
        setOpciones([
          "Contributivo A",
          "Contributivo B",
          "Contributivo C",
          "Subsidiado Z",
          "Subsidiado S",
        ]);
        break;
      case "Colsanitas":
        setOpciones([
          "Integral",
          "Modular",
          "Banco de la republica",
          "Bavaria",
        ]);
        break;
      case "Medisanitas":
        setOpciones(["Integral"]);
        break;
      case "Med Plus":
        setOpciones([
          "Pergamino-Indigo",
          "Café Selecto",
          "Celeste Plus",
          "Almendra Superior",
          "Cereza Excelso",
          "Selecto Joven",
          "Turquesa Plus",
          "Salud Plus",
        ]);
        break;
      case "Allianz":
        setOpciones(["Gold", "Group", "Colectivas", "Plus", "Care"]);
        break;
      case "Sura":
        setOpciones([
          "Plan Salud Global",
          "Plan Salud Clasico",
          "Plan Salud Evoluciona",
          "Plan Salud Juvenil Colectivo",
          "Plan Salud para todos estandar",
          "Plan salud para todos plus",
        ]);
        break;
      case "Seguros Bolivar":
        setOpciones([
          "Medico Familiar Empresarial",
          "Medico Familiar Bolivar",
          "Colectivas Swiss Life Danisco Colombia",
          "Bienestar y salud para disfrutar",
          "Salud Integral",
          "Salud Internacional Colectiva",
        ]);
        break;
      case "Ecopetrol":
        setOpciones(["Ecopetrol"]);
        break;
      case "Pan american Life":
        setOpciones(["Pan american Life"]);
        break;
      case "Mapfre":
        setOpciones(["Mapfre"]);
        break;
      case "Servisalud":
        setOpciones(["Servisalud"]);
        break;
      case "Famisanar":
        setOpciones(["Famisanar"]);
        break;
      case "Aliansalud":
        setOpciones(["Aliansalud"]);
        break;
      case "Particular":
        setOpciones(["Particular"]);
        break;
    }
  }, [props.convenio]);

  return (
    <div>
      <h1>Formulario de registros de ordenes - Sede </h1>
      <label>
        Seleccione quien esta llenando el formulario:
        <select onChange={(e) => props.setAsistido(e.target.value)}>
          <option hidden>Seleccione quien esta llenando el formulario</option>
          <option value="Siguiente">Paciente</option>
          <option value="Enviar">Asistido por RIIE</option>
        </select>
      </label>
      <label>
        Ingrese el nombre del paciente:{" "}
        <input
          type="text"
          placeholder="Por favor ingrese el nombre del paciente"
          onChange={(e) => props.setNompaciente(e.target.value)}
        />
      </label>
      <label>
        Ingrese el número de documento del paciente:
        <input
          type="text"
          placeholder="Por favor ingrese el número de documento del paciente"
          onChange={(e) => props.setNumdocpaciente(e.target.value)}
        />
      </label>
      <label>
        Seleccione el tipo de documento del paciente:
        <select onChange={(e) => props.setTipodocpaciente(e.target.value)}>
          <option hidden>Seleccione el tipo de documento del paciente</option>
          <option>Cedula de ciudadania</option>
          <option>Cedula de extranjeria</option>
          <option>Tarjeta de identidad</option>
          <option>Registro civil</option>
          <option>Pasaporte</option>
          <option>NIT</option>
        </select>
      </label>
      <label>
        Ingrese el nombre del responsable:{" "}
        <input
          type="text"
          placeholder="Por favor ingrese el nombre del responsable"
          onChange={(e) => props.setNomresponsable(e.target.value)}
        />
      </label>
      <label>
        Ingrese el número de documento del responsable:
        <input
          type="text"
          placeholder="Por favor ingrese el número de documento del responsable"
          onChange={(e) => props.setNumdocresponsable(e.target.value)}
        />
      </label>
      <label>
        Seleccione el tipo de documento del responsable:
        <select onChange={(e) => props.setTipodocresponsable(e.target.value)}>
          <option hidden>
            Seleccione el tipo de documento del responsable
          </option>
          <option>Cedula de ciudadania</option>
          <option>Cedula de extranjeria</option>
          <option>Tarjeta de identidad</option>
          <option>Registro civil</option>
          <option>Pasaporte</option>
          <option>NIT</option>
        </select>
      </label>
      <label>
        Seleccione su convenio:
        <select onChange={(e) => props.setConvenio(e.target.value)}>
          <option hidden>Seleccione su convenio</option>
          <option>AXA Colpatria Medicina Prepagada S.A.</option>
          <option>AXA Colpatria Seguros de Vida S.A.</option>
          <option>Sanitas</option>
          <option>Colsanitas</option>
          <option>Medisanitas</option>
          <option>Med Plus</option>
          <option>Allianz</option>
          <option>Sura</option>
          <option>Seguros Bolivar</option>
          <option>Ecopetrol</option>
          <option>Pan american Life</option>
          <option>Mapfre</option>
          <option>Servisalud</option>
          <option>Famisanar</option>
          <option>Aliansalud</option>
          <option>Particular</option>
        </select>
      </label>
      <label>
        Seleccione su tipo de plan:
        <select onChange={(e) => props.setPlan(e.target.value)}>
          <option hidden>Seleccione su tipo de plan</option>
          {opciones.map((opcion) => (
            <option>{opcion}</option>
          ))}
        </select>
      </label>
      <label>
        Ingrese su número de telefono:
        <input
          type="text"
          placeholder="Por favor ingrese su nùmero de telefono"
          onChange={(e) => props.setCelular(e.target.value)}
        />
      </label>
      <button onClick={() => props.Next()}>Siguiente</button>
    </div>
  );
};

export default Form;
