import React from "react";

const Upload = (props) => {
  return (
    <>
      <label>Por favor, cargue la orden medica.</label>
      <label>
        <b>
          Tener en cuenta que la orden medica debe cumplir con las siguientes
          caracteristicas:
        </b>
      </label>
      <label>Convenios (Ecopetrol)</label>
      <li>Debe indicar que las terapias son "a domicilio".</li>
      <li>
        La fecha de expedición de la orden no debe ser mayor a 3 meses (la
        vigencia de las órdenes es de 3 meses).
      </li>
      <li>
        La orden debe especificar claramente: nombre del usuario,
        identificación, fecha de expedición, servicio que requiere y cantidad de
        sesiones.
      </li>
      <li>El número de documento debe ser el correcto.</li>
      <li>La orden debe ser legible y no debe quedar cortada.</li>
      <label>Seguros (pólizas)</label>
      <li>
        La fecha de expedición de la orden no debe ser mayor a tres meses (la
        vigencia de las órdenes es de 3 meses).
      </li>
      <li>
        La orden debe especificar claramente: nombre del usuario,
        identificación, fecha de expedición, servicio que requiere y cantidad de
        sesiones.
      </li>
      <li>El número de documento debe ser el correcto.</li>
      <li>La orden debe ser legible y no debe quedar cortada.</li>
      <input
        name="userfile"
        type="file"
        onChange={(e) => props.setOrden(e.target.files[0])}
      />
      <button onClick={props.UploadFile}>Subir</button>
      <button onClick={props.Next} disabled={props.habilitador}>
        {props.asistido}
      </button>
      <button onClick={props.Previous}>Anterior</button>
    </>
  );
};

export default Upload;
